import { FC, PropsWithChildren, useState, useEffect } from 'react';
import Meta from '../seo/Meta';
import { IMeta } from '../../interfaces/meta.interface';
import localFont from 'next/font/local';
import Header from './header/Header';
import Menu from './menu/Menu';
import HeaderBanner from '../ui/banners/header-banner/HeaderBanner';
import StickyBanner from '../ui/banners/sticky-banner/StickyBanner';
import TopBanner from '../ui/banners/top-banner/TopBanner';
import FooterBanner from '../ui/banners/footer-banner/FooterBanner';
import Footer from './footer/Footer';
import AuthPopup from './auth-popup/AuthPopup';
import { useGetUrlPath } from '@/hooks/useGetUrlPath';
import styles from './Layout.module.scss';

const fontTahoma = localFont({
  src: [
    {
      path: './../../fonts/tahoma.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: './../../fonts/tahoma-bold.woff',
      weight: '700',
      style: 'bold',
    },
  ],
});

const Layout: FC<PropsWithChildren<IMeta>> = ({ children, title, description, pageHref, canonicalHref, cover, robotsNoFollow }) => {
  const URLS = ['/comments', '/mail', '/lib/letter', '/read', '/user', '/author', '/registration', '/forgot_pass', '/series', '/podcast/'];

  const [isAuthActive, setAuthActive] = useState(false);

  const pageUrl = useGetUrlPath();
  let isShortPage = false;

  if (URLS.some((url) => pageUrl.includes(url))) {
    isShortPage = true;
  }

  //Закрыть попап авторизации
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setAuthActive(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <Meta title={title} description={description} pageHref={pageHref} cover={cover} canonicalHref={canonicalHref} robotsNoFollow={robotsNoFollow}>
      <div className={isAuthActive ? `${styles.outerContainer} ${styles.outerContainer_auth}` : `${styles.outerContainer}`}>
        <HeaderBanner />
        <div className={styles.page} style={fontTahoma.style}>
          <div className={!!isShortPage ? `${styles.page_wrapper} ${styles.page_wrapperBgshort}` : `${styles.page_wrapper}`}>
            <div className={styles.container}>
              <Header setAuthActive={setAuthActive} />
              <div className={styles.content_wrap}>
                <Menu />
                <div className={styles.b_content}>
                  <div className={styles.top_banners}>
                    <TopBanner />
                  </div>
                  <div>{children}</div>
                  <div className={styles.bottom_banners}>
                    <FooterBanner />
                  </div>
                  {!!isShortPage ? <>{!!pageUrl.includes(URLS[1]) ? <Footer extraClassName="footer_bgshort" mailPage={true} /> : <Footer extraClassName="footer_bgshort" />}</> : <Footer />}
                </div>
              </div>
            </div>
          </div>
          <StickyBanner />
        </div>
        <AuthPopup isAuthActive={isAuthActive} setAuthActive={setAuthActive} />
      </div>
    </Meta>
  );
};

export default Layout;
