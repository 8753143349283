import { FC } from 'react';
import styles from './FormBtn.module.scss';

interface IInputBtn {
  id?: string;
  type?: string;
  value: string;
  captcha?: string | null;
  withCaptcha?: boolean;
}

const FormBtn: FC<IInputBtn> = ({ id, type, value, captcha, withCaptcha }) => {
  return (
    <div>
      {withCaptcha ? (
        <div className={captcha ? `${styles.formBtn}` : `${styles.formBtn} ${styles.formBtn_disabled}`}>
          <input type={type} value={value} id={id} className={`${styles.formBtn__txt}`} />
        </div>
      ) : (
        <div className={styles.formBtn}>
          <input type={type} value={value} id={id} className={styles.formBtn__txt} />
        </div>
      )}
    </div>
  );
};

export default FormBtn;
