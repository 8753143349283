import { FC } from 'react';
import Link from 'next/link';
import styles from './Footer.module.scss';
import BottomBanner from '@/components/ui/banners/bottom-banner/BottomBanner';

interface IFooterProps {
  extraClassName?: string;
  mailPage?: boolean;
}

const Footer: FC<IFooterProps> = ({ extraClassName, mailPage }) => {
  const getHeadingClassNames = () => (extraClassName ? `${styles.footer} ${styles[`${extraClassName}`]}` : styles.footer);

  const FOOTER_LINKS = [
    { link: '/redirect_to_litres', name: 'Litres.ru' },
    { link: '/redirect_to_self_pub', name: 'Стать автором' },
    { link: '/redirect_to_my_book', name: 'MyBook.ru' },
  ];

  return (
    <footer className={getHeadingClassNames()}>
      <div className={styles.footer__copyright}>
        <div className={styles.footer__copyrightDesc}>
          {!mailPage && (
            <span className={styles.footer__copyrightMail}>
              По всем вопросам пишите нам на почту: <a href="mailto:fantasy-worlds.ru@yandex.ru">fantasy-worlds.ru@yandex.ru</a>
            </span>
          )}
          <span className={styles.footer__copyrighTxt}>«Fantasy Worlds»</span>
        </div>
        <ul className={styles.footer__copyrightMenu}>
          {FOOTER_LINKS.map((item, i) => (
            <li className={styles.footer__copyrighItem}>
              <Link prefetch={false} key={`${item.name}-${i}`} className={styles.footer__copyrighLink} href={item.link}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.footer__banner}>
        <BottomBanner />
      </div>
    </footer>
  );
};

export default Footer;
