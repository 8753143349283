import { FC, useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { useGetUrlPath } from '@/hooks/useGetUrlPath';
import styles from './Menu.module.scss';

const Menu: FC = () => {
  const currentUrl = useGetUrlPath();
  const isMainPage = currentUrl === '/';

  const [mobileMenu, setMobileMenu] = useState(false);

  const refLeftMenu = useRef<HTMLDivElement>(null);

  const mobileMenuHandler = () => {
    setMobileMenu(!mobileMenu);
  };

  // useEffect(() => {
  //   const fixMenuPosition = () => {
  //     if (window.innerWidth < 991) {
  //       const bannerHeight = document.querySelector(
  //         "[data-litres-header-banner]"
  //       )?.clientHeight;
  //       refLeftMenu.current!.style.top = `${bannerHeight! + 10}px`;
  //     } else {
  //       refLeftMenu.current!.style.top = `auto`;
  //     }
  //   };

  //   fixMenuPosition();

  //   window.addEventListener("resize", fixMenuPosition);

  //   return () => {
  //     window.removeEventListener("resize", fixMenuPosition);
  //   };
  // });

  return (
    <div className={styles.b_left_menu} ref={refLeftMenu}>
      <div onClick={mobileMenuHandler} className={!!mobileMenu ? `${styles.mobile_icon} ${styles.act}` : `${styles.mobile_icon}`}></div>
      <div className={!!mobileMenu ? `${styles.mobile_wrap} ${styles.act}` : `${styles.mobile_wrap}`}>
        <nav>
          <ul>
            {!isMainPage && (
              <li>
                <Link prefetch={false} href="/">
                  Главная
                </Link>
              </li>
            )}
            <li>
              <Link prefetch={false} href="/new">
                Новинки
              </Link>
            </li>
            <li>
              <Link prefetch={false} href="/lib">
                Библиотека
              </Link>
            </li>
            <li>
              <Link prefetch={false} href="/genre">
                Жанры
              </Link>
            </li>
            <li>
              <Link prefetch={false} href="/series">
                Cерии
              </Link>
            </li>
            {/* <li>
              <Link prefetch={false} href="/lib/podcasts">
                Подкасты
              </Link>
            </li> */}
            <li>
              <Link prefetch={false} href="/comments">
                Комментарии
              </Link>
            </li>
            <li>
              <Link prefetch={false} href="/mail">
                Обратная связь
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Menu;
