import { FC } from 'react';
import Link from 'next/link';
import styles from './BottomBanner.module.scss';

const BottomBanner: FC = () => {
  return (
    <div className={styles.bottomBanner}>
      <Link prefetch={false} className={styles.bottomBanner__link} target="_blank" href="/redirect_to_self_pub">
        <img width="1200" height="90" className={styles.bottomBanner__img} src="/images/banners/selfpub-1200x90.png" alt="bottom-banner" />
        <img width="480" height="180" className={`${styles.bottomBanner__img} ${styles.bottomBanner__img_mobile}`} src="/images/banners/selfpub-480x180.png" alt="bottom-banner" />
      </Link>
    </div>
  );
};

export default BottomBanner;
