import { FC, PropsWithChildren } from 'react';
import Head from 'next/head';
import { IMeta } from '../../interfaces/meta.interface';

const Meta: FC<PropsWithChildren<IMeta>> = ({ title, description, pageHref, canonicalHref, cover, children, robotsNoFollow }) => {
  const findPageNumber = () => {
    const splitHref = pageHref?.split('/');

    //Client page number
    if (pageHref?.includes('page=') && !!splitHref?.length) {
      return splitHref[splitHref.length - 1];
    }

    //Server page number
    if (!!splitHref?.length) {
      const splitHrefLength = Number(splitHref?.length - 1);
      const pageNumber = splitHref[splitHrefLength];
      return Number(pageNumber) > 0 ? `page=${pageNumber}` : '';
    }

    //Plain pages
    return '';
  };

  return (
    <>
      <Head>
        {/* scripts for production */}
        <script type="text/javascript" src="/static/google-tag-manager.js"></script>
        <script type="text/javascript" src="/static/yandex-context-cb.js"></script>
        <script async type="text/javascript" src="/static/ya-counter.js"></script>
        <noscript><div><img src="https://mc.yandex.ru/watch/87192891" style={{ position: 'absolute', left: '-9999px' }} alt="" /></div></noscript>
        <script src="https://yandex.ru/ads/system/context.js" async />
        {/* scripts for production */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}${canonicalHref}${findPageNumber()}`} />
        {!!robotsNoFollow ? <meta name="robots" content="noindex, nofollow" /> : ``}
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_SITE_URL}${canonicalHref}`} />
        {cover ? (
          <>
            <meta property="og:type" content="books.book" />
            <meta property="og:image" content={cover}></meta>
          </>
        ) : (
          <>
            <meta property="og:image" content="/logo-desktop.svg"></meta>
          </>
        )}
        <link rel="icon" type="image/svg" href="/favicon/favicon.svg" />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
      </Head>
      {/* scripts for production */}
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KH53HJW" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
      </noscript>
      {/* scripts for production */}
      {children}
    </>
  );
};

export default Meta;
